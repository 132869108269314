<template>
    <v-dialog v-model="show" max-width="500px">
        <v-card>
            <v-form @submit="createItem($v.currentItem)">
                <v-card-title>
                    <span>New Item</span>
                    <v-spacer></v-spacer>
                    <v-btn icon type="submit" v-on:click.prevent="createItem($v.currentItem)">
                        <v-icon color="green">fas fa-check</v-icon>
                    </v-btn>
                    <v-btn icon @click.native="closeView()">
                        <v-icon color="red">fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Item Name"
                                              v-model="currentItem.itemName"
                                              :error-messages="itemNameErrors"
                                              @input="$v.currentItem.itemName.$touch()"
                                              @blur="$v.currentItem.itemName.$touch()">
                                </v-text-field>
                            </v-flex>
                            <v-flex xs6>
                                <v-autocomplete v-bind:items="currencies"
                                                label="Currency"
                                                v-model="currentItem.currency"
                                                :error-messages="currencyErrors"
                                                @input="$v.currentItem.currency.$touch()"
                                                @blur="$v.currentItem.currency.$touch()"
                                                item-text="currencyShort"
                                                item-value="currencyShort">
                                    <template slot="item"
                                              slot-scope="data">
                                        <template>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.currencyName"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.currencyShort"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs6>
                                <Category v-bind:options="{prepend:'', hint: false}"
                                          v-on:category="currentItem.category = $event"></Category>
                            </v-flex>
                            <v-flex xs12>
                                <!--<v-autocomplete :items="placeSold"
                                                v-model="currentItem.placeSold"
                                                label="Place Sold"
                                                :loading="loading"
                                                :error-messages="placeSoldErrors"
                                                @input="$v.currentItem.placeSold.$touch()"
                                                @blur="$v.currentItem.placeSold.$touch()"
                                                item-text="retailerName"
                                                item-value="retailerName">

                                    <template slot="selection"
                                              slot-scope="data">
                                        <v-chip :input-value="data.selected"
                                                class="chip--select-multi">
                                            {{ data.item.retailerName }}
                                        </v-chip>
                                    </template>
                                    <template slot="item"
                                              slot-scope="data">

                                        <template>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.retailerName"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.country"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>

                                    <template slot="no-data">

                                        <template>
                                            <v-btn @click.prevent="createPlaceSold = true"
                                                   small
                                                   block
                                                   outlined
                                                   text
                                                   color="green"
                                                   middle
                                                   right>
                                                <v-icon left>fas fa-plus</v-icon>
                                                <span>Create New Place Sold</span>
                                            </v-btn>
                                        </template>
                                    </template>
                                </v-autocomplete>-->
                                <PlaceSold v-on:placeSold="updatePlaceSold($event)"
                                           v-bind:options="' '"></PlaceSold>
                            </v-flex>
                            <v-flex xs12>
                                <v-autocomplete :items="placeSoldLocations"
                                                v-model="currentItem.placeSoldLocation"
                                                label="Place Sold Location"
                                                chips
                                                :error-messages="placeSoldLocationErrors"
                                                @input="$v.currentItem.placeSoldLocation.$touch()"
                                                @blur="$v.currentItem.placeSoldLocation.$touch()"
                                                item-text="locationName"
                                                item-value="locationName">

                                    <template slot="selection"
                                              slot-scope="data">
                                        <v-chip :input-value="data.selected"
                                                class="chip--select-multi">
                                            {{ data.item.locationName }}
                                        </v-chip>
                                    </template>
                                    <template slot="item"
                                              slot-scope="data">

                                        <template>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.locationName"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.locationAddress"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-checkbox label="Add Tax to item?"
                                            v-model="currentItem.isTaxed"></v-checkbox>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <v-alert v-if="priceListErrorsShow" type="error" style="margin-bottom: 25px;">
                        {{priceListErrors}}
                    </v-alert>
                    <PriceList v-on:priceList="updatePriceList($event)"></PriceList>
                </v-card-text>
            </v-form>

            <!--<AddPlaceSold v-if="createPlaceSold"
                          v-on:itemShow="closeNewPlaceSold($event)"
                          v-on:newPlaceSold="addNewPlaceSold($event)"></AddPlaceSold>-->
        </v-card>
    </v-dialog>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
    import { globalFunctions, isNullEmptyOrUndefined } from '../../javascript/globalFunctions'

    export default {
        mixins: [validationMixin],
        components: {
            PriceList: () => import('./itemsPriceList.vue'),
            //AddPlaceSold: () => import('../placeSold/addPlaceSold.vue'),
            PlaceSold: () => import('../placeSold/placeSoldSelect.vue'),
            Category: () => import('../../components/category/categorySelect.vue')
        },
        data() {
            return {
                show: true,
                currencies: [],
                category: [],
                placesSold: [],
                placeSoldLocations: [],
                createPlaceSold: false,
                priceListErrors: '',
                priceListErrorsShow: false,
                currentItem: {
                    id: 0,
                    itemName: '',
                    currency: '',
                    category: '',
                    isTaxed: false,
                    gct: '',
                    placeSold: '',
                    placeSoldLocation: '',
                    priceList: [],
                    //itemPrice: 0,
                    //quantity: 1,
                    //discountPercent: 0,
                    //discountAmount: 0,
                    dateChanged: '',
                    objectState: 'New'
                }
            }
        },
        computed: {
            itemNameErrors() {
                const errors = []
                if (!this.$v.currentItem.itemName.$dirty) return errors
                !this.$v.currentItem.itemName.required && errors.push('Item Name is required')
                return errors
            },
            currencyErrors() {
                const errors = []
                if (!this.$v.currentItem.currency.$dirty) return errors
                !this.$v.currentItem.currency.required && errors.push('Currency is required')
                return errors
            },
            //placeSoldErrors() {
            //    const errors = []
            //    if (!this.$v.currentItem.placeSold.$dirty) return errors
            //    !this.$v.currentItem.placeSold.required && errors.push('Place Sold is required')
            //    return errors
            //},
            placeSoldLocationErrors() {
                const errors = []
                if (!this.$v.currentItem.placeSoldLocation.$dirty) return errors
                !this.$v.currentItem.placeSoldLocation.required && errors.push('Place Sold Location is required')
                return errors
            },
            categoryErrors() {
                const errors = []
                if (!this.$v.currentItem.category.$dirty) return errors
                !this.$v.currentItem.category.required && errors.push('Category is required')
                return errors
            },
            loading() {
                if (globalFunctions.isNullOrEmpty(this.placeSold)) {
                    return true
                }
                return false
            }
        },
        methods: {
            closeView: function () {
                this.$emit('itemShow', false)
            },
            //addNewPlaceSold: function (place) {
            //    //Update Place Sold List
            //    this.placeSold = globalFunctions.returnAvailablePlaceSold()

            //    this.currentItem.placeSold = place.retailerName
            //},
            updatePriceList: function (priceList) {
                this.currentItem.priceList = priceList

                this.priceListErrorFinder()
            },
            returnNewItem: function () {
                this.$emit('newItem', this.currentItem)
            },
            priceListErrorFinder: function () {
                for (let i = 0; i < this.currentItem.priceList.length; i++) {
                    for (let x = 0; x < this.currentItem.priceList.length; x++) {
                        if (i !== x) {
                            //Check if they are equal, Then show error if yes
                            if (parseFloat(this.currentItem.priceList[i].itemPrice) === parseFloat(this.currentItem.priceList[x].itemPrice) && parseInt(this.currentItem.priceList[i].quantity) === parseInt(this.currentItem.priceList[x].quantity)) {
                                this.priceListErrors = "Duplicate Item Found"
                                this.priceListErrorsShow = true
                                return false
                            }
                            //Check if per unit is duplicated
                            else if (parseInt(this.currentItem.priceList[i].quantity) === parseInt(this.currentItem.priceList[x].quantity)) {
                                this.priceListErrors = "Duplicate Quantity Found"
                                this.priceListErrorsShow = true
                                return false
                            }
                        }
                    }

                    if (globalFunctions.isNullOrEmpty(this.currentItem.priceList[i].itemPrice)) {
                        this.priceListErrors = "Price cannot be empty"
                        this.priceListErrorsShow = true
                        return false
                    }
                    else if (globalFunctions.isNullOrEmpty(this.currentItem.priceList[i].quantity)) {
                        this.priceListErrors = "Quantity cannot be empty"
                        this.priceListErrorsShow = true
                        return false
                    }

                }

                this.priceListErrors = ""
                this.priceListErrorsShow = false
                return true
            },
            createItem: function ($v) {
                if (this.itemCheck($v)) {
                    this.addNewItem()
                    this.returnNewItem()
                    this.closeView()
                }
            },
            itemCheck: function ($v) {
                $v.$touch()

                if (!this.priceListErrorFinder()) {
                    return false
                }

                if (this.currentItem.priceList.length === 0) {
                    this.priceListErrors = "Please Enter Price"
                    this.priceListErrorsShow = true
                    return false
                }

                return true
            },
            addNewItem: function () {
                this.currentItem.dateChanged = globalFunctions.getCurrentDate()

                let newEntry = globalFunctions.stringify(this.currentItem)

                newEntry.id = globalFunctions.getNextIdForObject(this.$store.state.Items)

                this.$store.commit('addNewItemToStore', newEntry)
            },
            closeNewPlaceSold: function (item) {
                this.createPlaceSold = item
            },
            addGCTIfNeeded: function () {
                if (!isNullEmptyOrUndefined(this.currentItem.currency)) {
                    for (let i = 0; i < this.currencies.length; i++) {
                        if (this.currencies[i].currencyShort === this.currentItem.currency && this.currentItem.isTaxed) {
                            this.currentItem.gct = this.currencies[i].gct
                            break
                        }
                    }
                }
            },
            updatePlaceSold: function (place) {
                if (isNullEmptyOrUndefined(this.placeSold) || !(this.placeSold.includes(place))) {
                    this.placeSold.push(place)
                }

                this.currentItem.placeSold = place.retailerName

                this.updateCurrentPlaceSold()
            },
            updateCurrentPlaceSold: function () {
                if (!isNullEmptyOrUndefined(this.currentItem.placeSold)) {
                    for (let i = 0; i < this.placeSold.length; i++) {
                        if (this.placeSold[i].retailerName === this.currentItem.placeSold) {
                            this.placeSoldLocations = this.placeSold[i].locations
                            break
                        }
                    }
                }
            }
        },
        watch: {
            "currentItem.currency": function () {
                this.addGCTIfNeeded()
            },
            "currentItem.isTaxed": function () {
                this.addGCTIfNeeded()
            }
        },
        beforeMount() {
            this.currencies = globalFunctions.returnAvailableCurrencies()
            this.category = globalFunctions.returnCategories()
            this.placeSold = globalFunctions.returnAvailablePlaceSold()
        },
        validations: {
            currentItem: {
                itemName: {
                    required
                },
                currency: {
                    required
                },
                category: {
                    required
                },
                //placeSold: {
                //    required
                //},
                placeSoldLocation: {
                    required
                }
            }
        }
    }
</script>